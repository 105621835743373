import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

axios.defaults.withCredentials = true;

const poolData = {
    UserPoolId: 'eu-west-3_YIJ1vbtpr',
    ClientId: '4splav6iql8sufnhgd7p4kh720'
};
const userPool = new CognitoUserPool(poolData);

const AuthContext = createContext({
    user: null,
    loading: true,
    signIn: () => {},
    signOut: () => {},
    refreshToken: () => {}
});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const checkSession = async () => {
        return new Promise((resolve, reject) => {
            const cognitoUser = userPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.getSession((err, session) => {
                    if (err) {
                        reject(err);
                    } else if (session.isValid()) {
                        resolve({
                            email: session.getIdToken().payload.email,
                            token: session.getIdToken().getJwtToken()
                        });
                    } else {
                        reject(new Error("Invalid session"));
                    }
                });
            } else {
                reject(new Error("No current user"));
            }
        });
    };

    const refreshToken = async () => {
        return new Promise((resolve, reject) => {
            const cognitoUser = userPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.refreshSession(cognitoUser.getSignInUserSession().getRefreshToken(), (err, session) => {
                    if (err) {
                        console.error("Error refreshing token:", err);
                        signOut();
                        reject(err);
                    } else {
                        const token = session.getIdToken().getJwtToken();
                        const newUser = { email: cognitoUser.getUsername(), token };
                        setUser(newUser);
                        localStorage.setItem('authToken', token);
                        resolve(newUser);
                    }
                });
            } else {
                reject(new Error("No current user"));
            }
        });
    };

    useEffect(() => {
        const initializeAuth = async () => {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                try {
                    const sessionUser = await checkSession();
                    if (!sessionUser) {
                        await refreshToken();
                    } else {
                        setUser(sessionUser);
                    }
                } catch (error) {
                    console.error("Failed to refresh token:", error);
                    setUser(null);
                    localStorage.removeItem('authToken');
                }
            } else {
                setUser(null);
            }
            setLoading(false);
        };

        initializeAuth();

        // Set up periodic token refresh
        const refreshInterval = setInterval(refreshToken, 50 * 60 * 1000); // Refresh every 50 minutes

        return () => clearInterval(refreshInterval);
    }, []);

    const signIn = (email, password) => {
        return new Promise((resolve, reject) => {
            const authenticationDetails = new AuthenticationDetails({
                Username: email,
                Password: password,
            });

            const cognitoUser = new CognitoUser({
                Username: email,
                Pool: userPool
            });

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result) => {
                    const token = result.getIdToken().getJwtToken();
                    const newUser = { email, token };
                    setUser(newUser);
                    localStorage.setItem('authToken', token);
                    resolve(newUser);
                },
                onFailure: (err) => {
                    console.error("Authentication failed:", err);
                    reject(err);
                }
            });
        });
    };

    const signOut = () => {
        return new Promise((resolve) => {
            const cognitoUser = userPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.signOut(() => {
                    setUser(null);
                    localStorage.removeItem('authToken');
                    resolve();
                });
            } else {
                setUser(null);
                localStorage.removeItem('authToken');
                resolve();
            }
        });
    };

    return (
        <AuthContext.Provider value={{ user, loading, signIn, signOut, refreshToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);