import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useNavigate } from 'react-router-dom';
import { FaBars, FaSync } from 'react-icons/fa';
import './Header.css';
import './Sidebar.css';
import logo from './header.png';
import { useAuth } from './AuthContext';

const Header = ({ isSidebarOpen, setIsSidebarOpen, closeSidebar, isLoggedIn }) => {
    const navigate = useNavigate();
    const [message, setMessage] = React.useState('');
    const { signOut } = useAuth();

    const handleLogout = async () => {
        try {
            await signOut();
            closeSidebar();
            navigate('/login', { replace: true });
        } catch (error) {
            setMessage('Logout failed. Please try again.');
        }
    };

    const handleLinkClick = (path) => {
        closeSidebar();
        navigate(path);
    };

    const handleLogoClick = () => {
        if (isLoggedIn) {
            navigate('/dashboard');
        } else {
            navigate('/login');
        }
    };

    const handleRefresh = () => {
        window.location.reload();
    };

    return (
        <header className="header">
            {isLoggedIn && (
                <Menu
                    left
                    isOpen={isSidebarOpen}
                    onStateChange={({ isOpen }) => setIsSidebarOpen(isOpen)}
                    customBurgerIcon={<FaBars size={24} color="#d1d1d1" />}
                >
                    <button className="menu-item" onClick={() => handleLinkClick('/profile')}>Profile</button>
                    <button className="menu-item" onClick={handleLogout}>Logout</button>
                    {message && <div className="error-message">{message}</div>}
                </Menu>
            )}
            <div className="logo-container">
                <img src={logo} alt="Logo" className="logo" onClick={handleLogoClick} />
            </div>
            <button className="refresh-button" onClick={handleRefresh}>
                <FaSync />
            </button>
        </header>
    );
};

export default Header;