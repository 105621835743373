import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AWS from 'aws-sdk';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import moment from 'moment-timezone';
import './LeagueDetail.css';
import { useAuth } from './AuthContext';

AWS.config.update({
    region: 'eu-west-3',
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'eu-west-3:d575ab37-87dd-4c02-a78c-2552ef58e96f'
    })
});

const poolData = {
    UserPoolId: 'eu-west-3_YIJ1vbtpr',
    ClientId: '4splav6iql8sufnhgd7p4kh720'
};
const userPool = new CognitoUserPool(poolData);

axios.defaults.withCredentials = true;

const INITIAL_DISPLAY_COUNT = 5;
const LOAD_MORE_COUNT = 5;

const computeDeadline = (bet, timezone) => {
    if (!bet || !bet.event_deadline_timestamp) {
        console.error('Invalid bet object or missing deadline timestamp', bet);
        return 'Deadline unknown';
    }

    const eventDeadline = moment.utc(bet.event_deadline_timestamp).tz(timezone);
    if (!eventDeadline.isValid()) {
        console.error('Invalid deadline timestamp', bet.event_deadline_timestamp);
        return 'Invalid deadline';
    }

    let deadlineTime;
    if (bet.deadlineType === 'before') {
        deadlineTime = eventDeadline.clone().subtract(bet.deadline || 0, 'minutes');
    } else {
        deadlineTime = eventDeadline.clone().add(bet.deadline || 0, 'minutes');
    }

    const now = moment.tz(timezone);
    if (now.isAfter(deadlineTime)) {
        return 'Bet ended';
    }
    return `Deadline in ${deadlineTime.fromNow(true)}`;
};

const SharePopup = ({ leagueId, onClose }) => {
    const [copyStatus, setCopyStatus] = useState('');

    const copyToClipboard = async (text) => {
        setCopyStatus('Copying...');
        
        if (navigator.clipboard && window.isSecureContext) {
            try {
                await navigator.clipboard.writeText(text);
                setCopyStatus('Copied!');
                setTimeout(() => {
                    setCopyStatus('');
                    onClose();
                }, 2000);
            } catch (err) {
                console.error('Failed to copy: ', err);
                setCopyStatus('Failed to copy');
            }
        } else {
            let textArea = document.createElement("textarea");
            textArea.value = text;
            
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                document.execCommand('copy');
                setCopyStatus('Copied!');
                setTimeout(() => {
                    setCopyStatus('');
                    onClose();
                }, 2000);
            } catch (err) {
                console.error('Failed to copy: ', err);
                setCopyStatus('Failed to copy');
            }

            document.body.removeChild(textArea);
        }
    };

    return (
        <div className="share-popup">
            <h3>Share League</h3>
            <button onClick={() => copyToClipboard(leagueId)}>
                Copy League Code
            </button>
            <button onClick={() => copyToClipboard(`${window.location.origin}/leagues/${leagueId}`)}>
                Copy League Link
            </button>
            <button onClick={onClose}>Close</button>
            {copyStatus && <p className="copy-status">{copyStatus}</p>}
        </div>
    );
};

const ShareBetsModal = ({ bets, onClose, timezone }) => {
    const [selectedBets, setSelectedBets] = useState([]);
    const [copyStatus, setCopyStatus] = useState('');

    const toggleBetSelection = (bet) => {
        setSelectedBets(prevSelected => 
            prevSelected.some(b => b.betId === bet.betId)
                ? prevSelected.filter(b => b.betId !== bet.betId)
                : [...prevSelected, bet]
        );
    };

    const generateShareText = (bets) => {
        return bets.map(bet => 
            `[${bet.betDetails}]
            [${computeDeadline(bet, timezone)}]
            [Amount: $${bet.amount}]
            [${window.location.origin}/bets/${bet.betId}]

`
        ).join('\n');
    };

    const copyToClipboard = async (text) => {
        setCopyStatus('Copying...');
        
        let textArea = document.createElement("textarea");
        textArea.value = text;
        
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
            setCopyStatus('Copied!');
            setTimeout(() => {
                setCopyStatus('');
                onClose();
            }, 2000);
        } catch (err) {
            console.error('Failed to copy: ', err);
            setCopyStatus('Failed to copy');
        }

        document.body.removeChild(textArea);
    };

    return (
        <div className="share-bets-modal">
            <h3>Select Bets to Share</h3>
            {bets.length === 0 ? (
                <p>You haven't created any bets in this league yet.</p>
            ) : (
                <ul className="share-bets-list">
                    {bets.map((bet) => (
                        <li key={bet.betId} className="share-bet-item">
                            <label>
                                <input 
                                    type="checkbox"
                                    checked={selectedBets.some(b => b.betId === bet.betId)}
                                    onChange={() => toggleBetSelection(bet)}
                                />
                                <span className="share-bet-item-text">{bet.betDetails}</span>
                            </label>
                        </li>
                    ))}
                </ul>
            )}
            <button onClick={() => copyToClipboard(generateShareText(selectedBets))} disabled={selectedBets.length === 0}>
                Copy Selected Bets
            </button>
            <button onClick={onClose}>Close</button>
            {copyStatus && <p className="copy-status">{copyStatus}</p>}
        </div>
    );
};

const FilterPopup = ({ filterOption, setFilterOption, endedFilterOption, setEndedFilterOption, onApplyFilter, onClose }) => {
    const handleFilterChange = (option, value) => {
        if (option === 'main') {
            setFilterOption(value);
        } else {
            setEndedFilterOption(value);
        }
        onApplyFilter();
    };

    return (
        <div className="modal-overlay">
            <div className="filter-popup">
                <h3>Filter Bets</h3>
                <div>
                    <h4>All Bets</h4>
                    <label>
                        <input 
                            type="radio" 
                            value="all" 
                            checked={filterOption === 'all'} 
                            onChange={() => handleFilterChange('main', 'all')}
                        />
                        All Bets
                    </label>
                    <label>
                        <input 
                            type="radio" 
                            value="joined" 
                            checked={filterOption === 'joined'} 
                            onChange={() => handleFilterChange('main', 'joined')}
                        />
                        Joined Bets
                    </label>
                    <label>
                        <input 
                            type="radio" 
                            value="notJoined" 
                            checked={filterOption === 'notJoined'} 
                            onChange={() => handleFilterChange('main', 'notJoined')}
                        />
                        Bets Not Joined
                    </label>
                </div>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};
const LeagueDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [leagueDetails, setLeagueDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [showShareBetsModal, setShowShareBetsModal] = useState(false);
    const [error, setError] = useState(null);
    const [timezone, setTimezone] = useState(moment.tz.guess());
    const [ongoingBets, setOngoingBets] = useState([]);
    const [endedBets, setEndedBets] = useState([]);
    const [displayedOngoingBets, setDisplayedOngoingBets] = useState([]);
    const [displayedEndedBets, setDisplayedEndedBets] = useState([]);
    const [showLoadMoreOngoing, setShowLoadMoreOngoing] = useState(false);
    const [showLoadMoreEnded, setShowLoadMoreEnded] = useState(false);
    const [showEndedBets, setShowEndedBets] = useState(false);
    const token = localStorage.getItem('token');
    const [filterOption, setFilterOption] = useState('all');
    const [showFilterPopup, setShowFilterPopup] = useState(false);
    const { user } = useAuth();
    const currentUserEmail = user?.email || localStorage.getItem('email');
    const [endedFilterOption, setEndedFilterOption] = useState('allEnded');
    

    useEffect(() => {
        setTimezone(moment.tz.guess());
        fetchLeagueDetails();
    }, [id, navigate, token]);

    const fetchLeagueDetails = async () => {
        try {
            const response = await axios.post(`https://6dmskdjq9h.execute-api.eu-west-3.amazonaws.com/prod/leagues`, { league_id: id }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.status === 200) {
                const leagueData = response.data;
                console.log('Fetched league details:', leagueData);
                setLeagueDetails(leagueData);
                processBets(leagueData.bets);
            } else {
                throw new Error('Failed to fetch league details');
            }
        } catch (error) {
            console.error('Failed to fetch league details:', error);
            setError('Failed to fetch league details.');
        } finally {
            setLoading(false);
        }
    };


    const filterBets = (bets, isEnded = false) => {
        let filteredBets = bets;
        
        // Apply the general filter
        switch (filterOption) {
            case 'joined':
                filteredBets = filteredBets.filter(bet => hasUserJoinedBet(bet));
                break;
            case 'notJoined':
                filteredBets = filteredBets.filter(bet => !hasUserJoinedBet(bet));
                break;
        }
        
        // Apply the ended bet filter if it's for ended bets
        if (isEnded) {
                let usersJoined 
                        if (bets.betType === 'tombola') {
                            usersJoined = bets.outcomes ? Object.keys(bets.outcomes).length : 0;
                        }
                        else usersJoined = (bets.bet_for ? bets.bet_for.length : 0) + (bets.bet_against ? bets.bet_against.length : 0);
                switch (endedFilterOption) {
                    case 'validated':
                        filteredBets = filteredBets.filter(bet => bet.bet_validated === true);
                    break;
                case 'awaitingValidation':
                    filteredBets = filteredBets.filter(bets => 
                        bets.bet_validated === false && 
                        usersJoined > 1 &&
                        bets.close_request && 
                        Object.keys(bets.close_request).length > 0
                    );
                    break;
                case 'awaitingCloseRequest':
                    filteredBets = filteredBets.filter(bets => 
                        bets.bet_validated === false && 
                        usersJoined > 1 &&
                        (!bets.close_request || Object.keys(bets.close_request).length === 0)
                    );
                    break;
            }
        }
        
        return filteredBets;
    };

    const onApplyFilter = () => {
        if (leagueDetails && leagueDetails.bets) {
            processBets(leagueDetails.bets);
        }
    };

    const processBets = (bets) => {
        const now = moment();
        const sorted = bets.sort((a, b) => {
            const deadlineA = moment.utc(a.event_deadline_timestamp);
            const deadlineB = moment.utc(b.event_deadline_timestamp);
            return Math.abs(deadlineA.diff(now)) - Math.abs(deadlineB.diff(now));
        });
    
        const ongoing = sorted.filter(bet => moment.utc(bet.event_deadline_timestamp).isAfter(now));
        const ended = sorted.filter(bet => moment.utc(bet.event_deadline_timestamp).isSameOrBefore(now));
    
        const filteredOngoing = filterBets(ongoing);
        const filteredEnded = filterBets(ended, true);
    
        setOngoingBets(filteredOngoing);
        setEndedBets(filteredEnded);
        
        // Reset displayed bets to initial count
        setDisplayedOngoingBets(filteredOngoing.slice(0, INITIAL_DISPLAY_COUNT));
        setDisplayedEndedBets(filteredEnded.slice(0, INITIAL_DISPLAY_COUNT));
        
        setShowLoadMoreOngoing(filteredOngoing.length > INITIAL_DISPLAY_COUNT);
        setShowLoadMoreEnded(filteredEnded.length > INITIAL_DISPLAY_COUNT);
    };
    const hasUserJoinedBet = (bet) => {
        return bet.bet_for.includes(currentUserEmail) || 
               bet.bet_against.includes(currentUserEmail) ||
               (bet.betType === 'tombola' && bet.outcomes && Object.keys(bet.outcomes).includes(currentUserEmail));
    };
    
    useEffect(() => {
        if (leagueDetails && leagueDetails.bets) {
            processBets(leagueDetails.bets);
            if (showEndedBets) {
                const filteredEnded = getFilteredEndedBets();
                setDisplayedEndedBets(filteredEnded.slice(0, INITIAL_DISPLAY_COUNT));
                setShowLoadMoreEnded(filteredEnded.length > INITIAL_DISPLAY_COUNT);
            }
        }
    }, [filterOption, leagueDetails, showEndedBets,endedFilterOption]);
    

    const getFilteredEndedBets = () => {
        return filterBets(endedBets);
    };

    const toggleEndedBets = () => {
        if (!showEndedBets) {
            const filteredEnded = getFilteredEndedBets();
            setDisplayedEndedBets(filteredEnded.slice(0, INITIAL_DISPLAY_COUNT));
            setShowLoadMoreEnded(filteredEnded.length > INITIAL_DISPLAY_COUNT);
        }
        setShowEndedBets(!showEndedBets);
    };
    const loadMoreOngoing = () => {
        const currentCount = displayedOngoingBets.length;
        const newBets = ongoingBets.slice(currentCount, currentCount + LOAD_MORE_COUNT);
        setDisplayedOngoingBets(prevBets => [...prevBets, ...newBets]);
        setShowLoadMoreOngoing(currentCount + LOAD_MORE_COUNT < ongoingBets.length);
    };

    const loadMoreEnded = () => {
        const filteredEnded = getFilteredEndedBets();
        const currentCount = displayedEndedBets.length;
        const newBets = filteredEnded.slice(currentCount, currentCount + LOAD_MORE_COUNT);
        setDisplayedEndedBets(prevBets => [...prevBets, ...newBets]);
        setShowLoadMoreEnded(currentCount + LOAD_MORE_COUNT < filteredEnded.length);
    };
    

    const renderBetItem = (bet) => {
        const deadline = computeDeadline(bet, timezone);
        const isOngoing = moment.utc(bet.event_deadline_timestamp).isAfter(moment());
        const joinedPlayersCount = getJoinedPlayersCount(bet);
    
        return (
            <li key={bet.betId} className={`bet-item ${isOngoing ? 'ongoing' : 'ended'}`} onClick={() => navigate(`/bets/${bet.betId}`)}>
                <div className="bet-header">
                    <span className="bet-creator">{bet.nickname}</span>
                    <span className="bet-deadline">{deadline}</span>
                </div>
                <div className="bet-details">
                    <p><strong>Details:</strong> {bet.betDetails}</p>
                    <p><strong>Amount:</strong> ${bet.amount}</p>
                    <p><strong>Type:</strong> {bet.betType}</p>
                    <p><strong>Status:</strong> {isOngoing ? 'Ongoing' : 'Ended'}</p>
                </div>
                <div className="bet-players-info">
                    <svg className="user-logo" viewBox="0 0 24 24" width="16" height="16">
                        <path fill="#4CAF50" d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                    </svg>
                    <span>Users joined: {joinedPlayersCount}</span>
                </div>
            </li>
        );
    };

    const getJoinedPlayersCount = (bet) => {
        if (bet.betType === 'tombola') {
            return bet.outcomes ? Object.keys(bet.outcomes).length : 0;
        }
        return (bet.bet_for ? bet.bet_for.length : 0) + (bet.bet_against ? bet.bet_against.length : 0);
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        return <div className="error">{error}</div>;
    }

    return (
        <div className="league-detail-container">
            <div className="league-detail-header">
            <h2>{leagueDetails.leagueName || 'No League Name Available'}</h2>
            <div className="header-buttons">
                <button onClick={() => setShowSharePopup(true)}>Share League</button>
                <button onClick={() => setShowShareBetsModal(true)}>Share Bets</button>
                <button onClick={() => setShowFilterPopup(true)} className="filter-button">
                    <span className="filter-icon">🔍</span> Filter
                </button>
            </div>
        </div>


            <div className="league-detail-buttons">
                <button onClick={() => navigate('/dashboard')}>Back to Dashboard</button>
                <button onClick={() => navigate(`/leagues/${id}/outcome`)}>View Outcome</button>
                <button onClick={() => navigate(`/leagues/${id}/create-bet`)}>Create Bet</button>
            </div>

            <div className="bets-list-container">
                <h3>Ongoing Bets</h3>
                {displayedOngoingBets.length > 0 ? (
                    <ul className="bets-list">
                        {displayedOngoingBets.map(renderBetItem)}
                    </ul>
                ) : (
                    <p>No ongoing bets available.</p>
                )}
                
                {showLoadMoreOngoing && (
                    <button className="load-more-button" onClick={loadMoreOngoing}>
                        Load More Ongoing Bets
                    </button>
                )}
                
                <h3>Ended Bets</h3>
                <button onClick={toggleEndedBets}>
                    {showEndedBets ? 'Hide Ended Bets' : 'Show Ended Bets'}
                </button>

                {showEndedBets && (
                    <>
                        {displayedEndedBets.length > 0 ? (
                            <ul className="bets-list">
                                {displayedEndedBets.map(renderBetItem)}
                            </ul>
                        ) : (
                            <p>No ended bets available.</p>
                        )}
                        
                        {showLoadMoreEnded && (
                            <button className="load-more-button" onClick={loadMoreEnded}>
                                Load More Ended Bets
                            </button>
                        )}
                    </>
                )}
            </div>
            {showFilterPopup && (
                <FilterPopup 
                    filterOption={filterOption}
                    setFilterOption={setFilterOption}
                    endedFilterOption={endedFilterOption}
                    setEndedFilterOption={setEndedFilterOption}
                    onApplyFilter={onApplyFilter}
                    onClose={() => setShowFilterPopup(false)}
                />
            )}

            {showSharePopup && (
                <SharePopup 
                    leagueId={leagueDetails.leagueId} 
                    onClose={() => setShowSharePopup(false)} 
                />
            )}
            
            {showShareBetsModal && (
                <ShareBetsModal 
                    bets={leagueDetails.bets.filter(bet => bet.email === currentUserEmail)}
                    onClose={() => setShowShareBetsModal(false)}
                    timezone={timezone}
                />
            )}
        </div>
    );
};

export default LeagueDetail;