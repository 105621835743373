import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaHome, FaBell } from 'react-icons/fa';
import axios from 'axios';
import moment from 'moment';
import { useAuth } from './AuthContext';
import './Footer.css';

const Footer = ({ closeSidebar }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [unseenBets, setUnseenBets] = useState(0);
    const { user } = useAuth();
    const token = localStorage.getItem('token');
    const stored_email = localStorage.getItem('email');
    
    let email = stored_email || user?.email;

    useEffect(() => {
        const fetchUnseenBetsCount = async () => {
            try {
                if (!email) return;
                const response = await axios.post(
                    'https://6dmskdjq9h.execute-api.eu-west-3.amazonaws.com/prod/get_unseen_bets',
                    { email },
                    { headers: { 'Authorization': `Bearer ${token}` } }
                );
                console.log("Response: ", response);
                if (response.status === 200) {
                    const now = moment();
                    const validUnseenBets = response.data.unseen_bets.filter(bet =>
                        moment(bet.event_deadline_timestamp).isAfter(now)
                    );
                    setUnseenBets(validUnseenBets.length);
                }
            } catch (error) {
                console.error('Failed to fetch unseen bets count:', error);
            }
        };
        fetchUnseenBetsCount();
    }, [user, token, email]);

    const isActive = (path) => {
        if (path === '/dashboard') {
            return location.pathname === '/dashboard' || location.pathname === '/';
        }
        return location.pathname === path;
    };

    const handleNavigation = (path) => {
        navigate(path);
        closeSidebar();
    };

    return (
        <footer className="app-footer">
            <button
                className={`footer-button ${isActive('/dashboard') ? 'active' : ''}`}
                onClick={() => handleNavigation('/dashboard')}
                aria-label="Home"
            >
                <FaHome />
                <span className="button-text">Home</span>
            </button>
            <button
                className={`footer-button ${isActive('/unseen-bets') ? 'active' : ''}`}
                onClick={() => handleNavigation('/unseen-bets')}
                aria-label="Unseen Bets"
            >
                <FaBell />
                {unseenBets > 0 && <span className="notification-dot">{unseenBets}</span>}
                <span className="button-text">Unseen Bets</span>
            </button>
        </footer>
    );
};

export default Footer;