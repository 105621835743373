import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './LoginPage.css';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { user, loading, refreshToken , signIn } = useAuth();
    
    if (loading) {
        return <div>Loading...</div>;
    }
    if (user) {
        navigate('/dashboard', { replace: true });
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            await signIn(email, password);
            const redirectUrl = localStorage.getItem('redirectAfterLogin') || '/dashboard';
            localStorage.removeItem('redirectAfterLogin');
            navigate(redirectUrl, { replace: true });
        } catch (err) {
            if (err.code === 'UserNotConfirmedException') {
                navigate('/confirm-signup', { state: { email } });
            } else {
                setError(err.message || 'An error occurred during login. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-container">
            <h1>Login</h1>
            {error && <div className="error-message">{error}</div>}
            <form onSubmit={handleSubmit} className="login-form">
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                        autoComplete="email"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        id="password"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                        autoComplete="current-password"
                    />
                </div>
                <button type="submit" className="login-button" disabled={isLoading}>
                    {isLoading ? 'Logging in...' : 'Login'}
                </button>
                <button type="button" className="sign-up-button" onClick={() => navigate('/sign-up')}>Sign Up</button>
                <button type="button" className="forgot-password-button" onClick={() => navigate('/forgot-password')}>Forgot Password</button>
            </form>
        </div>
    );
}

export default LoginPage;