import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import { useAuth } from './AuthContext';
import Modal from 'react-modal';
import './TombolaDisplayBetDetails.css';
import { FaArrowLeft, FaCheck, FaTimes, FaShare, FaEllipsisH } from 'react-icons/fa';

const TombolaDisplayBetDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [betData, setBetData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedOutcome, setSelectedOutcome] = useState('');
    const [showCloseModal, setShowCloseModal] = useState(false);
    const [winningOutcome, setWinningOutcome] = useState('');
    const [closeRequests, setCloseRequests] = useState([]);
    const [validatedBy, setValidatedBy] = useState([]);
    const [challengedBy, setChallengedBy] = useState([]);
    const [existingCloseRequest, setExistingCloseRequest] = useState(null);
    const currentUserEmail = localStorage.getItem('email') || user?.email;

    const fetchBetDetails = async () => {
        try {
            const response = await axios.post('https://6dmskdjq9h.execute-api.eu-west-3.amazonaws.com/prod/get_bet_details', 
                { betId: id, email: currentUserEmail },
                { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
            );
            if (response.status === 200 && response.data.betData) {
                console.log(response.data);
                setBetData(response.data.betData);
                await fetchCloseRequests();
            } else {
                throw new Error('Failed to fetch bet details or received empty response');
            }
        } catch (error) {
            console.error('Error fetching bet details:', error);
            setError('Failed to load bet details. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBetDetails();
    }, [id, currentUserEmail]);

    const fetchCloseRequests = async () => {
        try {
            const response = await axios.post('https://6dmskdjq9h.execute-api.eu-west-3.amazonaws.com/prod/get_tombola_close_requests', 
                { betId: id, email: currentUserEmail },
                { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
            );
            if (response.status === 200) {
                console.log(response.data);
                setCloseRequests(response.data.close_requests || []);
                setValidatedBy(response.data.validated_by || []);
                setChallengedBy(response.data.challenged_by || []);
                if (response.data.close_requests && response.data.close_requests.length > 0) {
                    setExistingCloseRequest(response.data.close_requests[0]);
                }
            }
        } catch (error) {
            console.error('Error fetching close requests:', error);
        }
    };

    const generateShareText = (bet) => {
        return `Check out this bet!
        Details: ${bet.betDetails}
        Amount: $${bet.amount}
        Type: ${bet.betType}
        Deadline: ${bet.deadline} mins ${bet.deadlineType} ${bet.event || 'game start'}
        Join here: ${window.location.href}`;
    };

    const copyToClipboard = (text) => {
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    alert('Bet details copied to clipboard!');
                })
                .catch((err) => {
                    console.error('Failed to copy: ', err);
                    fallbackCopyTextToClipboard(text);
                });
        } else {
            fallbackCopyTextToClipboard(text);
        }
    };
    
    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
    
        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'Bet details copied to clipboard!' : 'Unable to copy bet details';
            alert(msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            alert('Failed to copy bet details. Please try again.');
        }
    
        document.body.removeChild(textArea);
    };
    
    const handleShareBet = () => {
        if (betData) {
            const shareText = generateShareText(betData);
            copyToClipboard(shareText);
        }
    };

    const handleJoinBet = async () => {
        if (!selectedOutcome) {
            alert('Please select an outcome before joining the bet.');
            return;
        }

        try {
            const response = await axios.post('https://6dmskdjq9h.execute-api.eu-west-3.amazonaws.com/prod/join_tombola_bet', {
                betId: id,
                email: currentUserEmail,
                outcome: selectedOutcome
            }, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });

            if (response.status === 200) {
                alert('Successfully joined the bet');
                await fetchBetDetails();
            } else {
                throw new Error('Failed to join the bet');
            }
        } catch (error) {
            console.error('Error joining bet:', error);
            alert('Failed to join the bet. Please try again.');
        }
    };

    const handleCloseBet = async () => {
        try {
            const response = await axios.post('https://6dmskdjq9h.execute-api.eu-west-3.amazonaws.com/prod/close_tombola_bet', {
                betId: id,
                email: currentUserEmail,
                winningOutcome: winningOutcome
            }, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });

            if (response.status === 200) {
                alert('Bet close request submitted. Waiting for other players to validate.');
                setShowCloseModal(false);
                await fetchBetDetails();
            } else {
                alert('Failed to submit close request.');
            }
        } catch (error) {
            console.error('Failed to close the bet:', error);
            alert('Failed to close the bet, please try again.');
        }
    };

    const handleValidateCloseBet = async () => {
        try {
            const response = await axios.post('https://6dmskdjq9h.execute-api.eu-west-3.amazonaws.com/prod/validate_tombola_close_bet', {
                betId: id,
                email: currentUserEmail,
                accept: true
            }, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });

            if (response.status === 200) {
                alert('Bet validated successfully.');
                await fetchBetDetails();
            } else {
                alert('Failed to validate bet.');
            }
        } catch (error) {
            console.error('Error validating bet:', error);
            alert('Failed to validate bet. Please try again.');
        }
    };

    const handleChallengeOutcome = async () => {
        try {
            const response = await axios.post('https://6dmskdjq9h.execute-api.eu-west-3.amazonaws.com/prod/challenge_tombola_bet_outcome', {
                betId: id,
                email: currentUserEmail
            }, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });

            if (response.status === 200) {
                alert('Outcome challenged successfully.');
                await fetchBetDetails();
            } else {
                alert('Failed to challenge outcome.');
            }
        } catch (error) {
            console.error('Error challenging outcome:', error);
            alert('Failed to challenge outcome. Please try again.');
        }
    };

    if (loading) return <div className="loading">Loading bet details...</div>;
    if (error) return <div className="error">{error}</div>;
    if (!betData) return <div className="error">No bet data available</div>;

    const eventDeadline = moment.tz(betData.event_deadline_timestamp, betData.timezone || 'UTC');
    const deadlineTime = betData.deadlineType === 'before' 
        ? eventDeadline.clone().subtract(betData.deadline || 0, 'minutes')
        : eventDeadline.clone().add(betData.deadline || 0, 'minutes');

    const isDeadlinePassed = moment().isAfter(deadlineTime);
    const userParticipating = betData.outcomes && betData.outcomes[currentUserEmail];
    const hasUserValidated = validatedBy.some(v => v.user_id === currentUserEmail);
    const hasUserChallenged = challengedBy.some(c => c.user_id === currentUserEmail);
    const isUserSubmitter = closeRequests.some(cr => cr.user_id === currentUserEmail);

    const getRelativeDeadline = () => {
        const now = moment();
        if (now.isAfter(deadlineTime)) {
            return "Deadline has passed";
        } else {
            return `Deadline in ${deadlineTime.fromNow(true)}`;
        }
    };

    return (
        <div className="tombola-bet-container">
            <h1>Tombola Bet Details</h1>
            <div className="bet-info">
                <p><strong>Created by:</strong> {betData.nickname || 'Unknown'}</p>
                <p><strong>Bet Details:</strong> {betData.betDetails || 'No details provided'}</p>
                <p><strong>Amount:</strong> ${betData.amount || 0}</p>
                <p><strong>Event:</strong> {betData.event || 'Not specified'}</p>
                <p><strong>Deadline:</strong> {getRelativeDeadline()}</p>
            </div>
            <button className="share-button" onClick={handleShareBet}>
                <FaShare /> Share Bet
            </button>

            <div className="outcomes-section">
                <h2>Current Outcomes</h2>
                {betData.outcomes && Object.keys(betData.outcomes).length > 0 ? (
                    <ul>
                        {Object.entries(betData.outcomes).map(([email, data]) => (
                            <li key={email}>
                                {data.nickname}: {data.outcome}
                                {closeRequests.some(cr => cr.user_id === email) && (
                                    <span className="user-action close"> (Submitted close request: {closeRequests.find(cr => cr.user_id === email).winning_outcome})</span>
                                )}
                                {validatedBy.some(v => v.user_id === email) && (
                                    <span className="user-action validated"> (Validated)</span>
                                )}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No outcomes submitted yet.</p>
                )}
            </div>

            {!isDeadlinePassed && !userParticipating && (
                <div className="join-bet-section">
                    <h2>Join the Bet</h2>
                    <input 
                        type="text" 
                        value={selectedOutcome} 
                        onChange={(e) => setSelectedOutcome(e.target.value)} 
                        placeholder="Enter your outcome"
                    />
                    <button onClick={handleJoinBet}>Join Bet</button>
                </div>
            )}

            {betData.bet_validated ? (
                <div className="bet-validated-message">
                    <p>This bet has been validated. The winning outcome is: {betData.winning_outcome}</p>
                </div>
            ) : (
                <>
                    {isDeadlinePassed && userParticipating && !existingCloseRequest && (
                        <button onClick={() => setShowCloseModal(true)}>Close Bet</button>
                    )}

                    {isDeadlinePassed && userParticipating && existingCloseRequest && (
                        <div className="close-request-status">
                            {isUserSubmitter ? (
                                <p>You have submitted a close request. Waiting for other players to validate.</p>
                            ) : (
                                <p>A close request has been submitted with outcome: {existingCloseRequest.winning_outcome}</p>
                            )}
                        </div>
                    )}
                    {isDeadlinePassed && userParticipating && existingCloseRequest && !hasUserValidated && !hasUserChallenged && (
                        <div className="validate-challenge-section">
                            {!isUserSubmitter && (
                                <>
                                    <button onClick={handleValidateCloseBet}>Validate Close Bet</button>
                                    <button onClick={handleChallengeOutcome}>Challenge Outcome</button>
                                </>
                            )}
                        </div>
                    )}
                </>
            )}

            {isDeadlinePassed && (
                <p className="deadline-message">The deadline for this bet has passed. No more entries can be accepted.</p>
            )}

            {userParticipating && (
                <p className="participation-message">
                    You are participating in this bet. Your outcome: {betData.outcomes[currentUserEmail].outcome}
                </p>
            )}

            <button onClick={() => navigate(`/leagues/${betData.leagueId}`)}>Back to League</button>

            <Modal 
                isOpen={showCloseModal}
                onRequestClose={() => setShowCloseModal(false)}
                contentLabel="Close Bet Modal"
                className="ReactModal__Content"
                overlayClassName="ReactModal__Overlay"
            >
                <h2>Select Winning Outcome</h2>
                <select 
                    value={winningOutcome} 
                    onChange={(e) => setWinningOutcome(e.target.value)}
                >
                    <option value="">Select an outcome</option>
                    {Object.entries(betData.outcomes).map(([email, data]) => (
                        <option key={email} value={data.outcome}>{data.outcome}</option>
                    ))}
                    <option value="no_winning_outcome">No Winning Outcome</option>
                </select>
                <button onClick={handleCloseBet}>Submit</button>
                <button onClick={() => setShowCloseModal(false)}>Cancel</button>
            </Modal>
        </div>
    );
};

export default TombolaDisplayBetDetails;