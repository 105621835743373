import React, { useMemo } from 'react';
import Select from 'react-select';

const TimezoneSelector = ({ value, onChange }) => {
  const timezoneOptions = useMemo(() => {
    try {
      const timezones = Intl.supportedValuesOf('timeZone');
      return timezones.map(timezone => ({
        value: timezone,
        label: timezone.replace(/_/g, ' ')
      }));
    } catch (error) {
      console.error('Error fetching timezones:', error);
      return [];
    }
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: '#ccc',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#aaa'
      }
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999
    })
  };

  return (
    <div className="timezone-selector-container">
      <Select
        options={timezoneOptions}
        value={value}
        onChange={onChange}
        styles={customStyles}
        placeholder="Search for a timezone..."
        isClearable
        isSearchable
        className="timezone-selector"
      />
    </div>
  );
};

export default TimezoneSelector;